import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";

import { SvgLogoVisa } from "~animations/__layers__/logos/SvgLogoVisa";

import type { BoxProps } from "~components/Box";

export function AnimLayerCreditCardInfo({ ...rest }: BoxProps) {
  return (
    <Box
      className={variantBackgroundColor.background_mockUiCard}
      padding="phoneSpacing2"
      borderRadius="md"
      alignItems="center"
      display="flex"
      gap="phoneSpacing1"
      textAppearance="phone_text_sm"
      {...rest}
    >
      <SvgLogoVisa />
      <Box>**** 1234</Box>
      <Box marginLeft="auto">10/24</Box>
    </Box>
  );
}
