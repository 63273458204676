import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { SvgIconCasino } from "~animations/__layers__/SvgIconCasino";
import { SvgIconPokerChip } from "~animations/__layers__/SvgIconPokerChip";
import { SvgIconSlotMachine } from "~animations/__layers__/SvgIconSlotMachine";

import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Map of bespoke iGaming related SVG icons as react components
 * ------------------------------------------------------------------------------
 */

const ICON_MAP = {
  SvgIconPokerChip,
  SvgIconCasino,
  SvgIconSlotMachine,
  "": () => {
    return null;
  },
} as const;

type IconKey = keyof typeof ICON_MAP;

/**
 * ------------------------------------------------------------------------------
 * Abstraction of single item in the iGaming icons layer
 * ------------------------------------------------------------------------------
 */

interface AnimLayerIgamingIconsItemProps {
  icon?: IconKey;
  image?: Array<StoryblokBlok & StoryblokFieldMedia>;
  text: string;
}

function AnimLayerIgamingIconsItem({
  icon = "",
  text,
  image: imageArray,
  ...rest
}: AnimLayerIgamingIconsItemProps) {
  const Icon = ICON_MAP[icon];
  const [image] = imageArray || [];

  return (
    <StoryblokEditable {...rest}>
      <Box
        display="flex"
        borderRadius="md"
        flexDirection="column"
        gap="phoneSpacing1"
        alignItems="center"
        justifyContent="center"
        className={variantBackgroundColor.background_mockUiCard}
        padding="phoneSpacing0"
        data-motion={ANIMATED.secondary}
        aspectRatio="square"
      >
        <ShowChildren when={image}>
          <Box
            height="phoneSpacing5"
            width="phoneSpacing5"
            display="flex"
            justifyContent="center"
          >
            <Image margin="auto" objectFit="contain" image={image} />
          </Box>
        </ShowChildren>
        <ShowChildren when={icon}>
          <Box height="phoneSpacing3" width="phoneSpacing3">
            <Icon />
          </Box>
        </ShowChildren>
        <Box textAppearance="phone_text_sm">{text}</Box>
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Main component - renders a list of cards with iGaming related icons and text
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerIgamingIconsProps extends BoxProps, StoryblokBlok {
  items: Array<StoryblokBlok & AnimLayerIgamingIconsItemProps>;
}

export function AnimLayerIgamingIcons({
  items,
  ...rest
}: AnimLayerIgamingIconsProps) {
  if (!Array.isArray(items)) return null;

  return (
    <Box
      display="grid"
      gridTemplateColumns={3}
      alignItems="stretch"
      gap="phoneSpacing1"
      {...rest}
    >
      {items.map((item) => {
        return <AnimLayerIgamingIconsItem key={item._uid} {...item} />;
      })}
    </Box>
  );
}
