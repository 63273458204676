import React from "react";

import type { SVGProps } from "react";

export function PayByBankSVG(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 127 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.8361 6.89147L19.756 21.6829C19.5269 23.3193 17.8878 24.3657 16.3022 23.8812L6.63098 20.9293C5.68185 20.6401 5.08274 19.701 5.22093 18.7174L5.63458 15.7918L9.40108 16.4545C10.5257 16.6527 11.5893 15.8827 11.7503 14.7508L13.2176 4.4332L18.886 3.83773C20.6297 3.6559 22.0806 5.15686 21.8352 6.89056L21.8361 6.89147Z"
        fill="#060606"
      />
      <path
        d="M13.5203 2.30654L13.2175 4.43389L8.71374 4.90846C7.79098 5.00573 7.05459 5.71939 6.9264 6.63488L5.63362 15.7907L1.39256 15.0434C0.500709 14.8852 -0.108405 14.0506 0.0161453 13.1515L1.40983 3.21476C1.50711 2.5111 2.03622 1.94926 2.73261 1.80107L11.1075 0.0437343C12.4775 -0.24264 13.7194 0.919221 13.5212 2.30563L13.5203 2.30654Z"
        fill="#060606"
      />
      <path
        d="M37.5666 4.8412C36.8684 4.46755 36.0648 4.28027 35.1557 4.28027H30.4082V17.3644H32.7819V12.9342H35.3984C36.2584 12.9342 37.0184 12.7469 37.6785 12.3733C38.3385 11.9996 38.8558 11.4887 39.2303 10.8405C39.604 10.1805 39.7913 9.43228 39.7913 8.5977C39.7913 7.76313 39.5922 7.0031 39.1931 6.35489C38.8067 5.70669 38.2649 5.20212 37.5666 4.8412ZM36.8184 10.1687C36.4448 10.5678 35.9584 10.7669 35.3602 10.7669H32.781V6.43035H35.0984C35.7711 6.43035 36.3193 6.62945 36.743 7.02855C37.1794 7.42766 37.3975 7.95041 37.3975 8.59861C37.3975 9.24682 37.2048 9.75774 36.8184 10.1687Z"
        fill="#060606"
      />
      <path
        d="M46.6665 8.33676C46.0555 7.97584 45.2837 7.79492 44.3491 7.79492C43.6136 7.79492 42.9845 7.91311 42.4608 8.15039C41.9372 8.37494 41.5072 8.67404 41.1708 9.04769C40.8344 9.40862 40.5726 9.78954 40.3853 10.1877L42.3854 10.8978C42.6099 10.5241 42.89 10.2432 43.2263 10.0568C43.5627 9.87045 43.9364 9.77681 44.3482 9.77681C44.8091 9.77681 45.1773 9.88863 45.451 10.1132C45.7255 10.3377 45.9246 10.6614 46.0492 11.085C46.1146 11.2814 46.1637 11.5005 46.1983 11.7396H43.9182C42.7218 11.7396 41.7935 11.9951 41.1335 12.506C40.4735 13.0042 40.1426 13.746 40.1426 14.7306C40.1426 15.6407 40.448 16.3443 41.0581 16.8425C41.6808 17.3407 42.5536 17.5898 43.6745 17.5898C44.7955 17.5898 45.6064 17.2344 46.1046 16.5243C46.1683 16.4325 46.2273 16.3361 46.2819 16.2361L46.3283 17.3662H48.4402L48.4965 12.2069C48.5093 11.2969 48.3592 10.5123 48.0483 9.85136C47.7492 9.19134 47.2883 8.68586 46.6655 8.33767L46.6665 8.33676ZM45.9555 14.5988C45.7682 14.9225 45.5073 15.1843 45.17 15.3843C44.8337 15.5834 44.4473 15.6834 44.0109 15.6834C43.5245 15.6834 43.1445 15.5961 42.8709 15.4216C42.5963 15.2343 42.4599 14.9852 42.4599 14.6743C42.4599 14.2879 42.6218 13.9888 42.9463 13.777C43.27 13.5651 43.8436 13.4588 44.6664 13.4588H46.2555V13.5333C46.2555 13.907 46.1555 14.2624 45.9564 14.5988H45.9555Z"
        fill="#060606"
      />
      <path
        d="M54.1581 14.7179L51.3908 8.01855H48.9043L53.0926 17.2843L52.6426 18.3362C52.5181 18.6353 52.3499 18.8535 52.1381 18.9908C51.939 19.1399 51.7017 19.2153 51.428 19.2153C51.3035 19.2153 51.1417 19.1844 50.9416 19.1217C50.7544 19.059 50.5489 18.9662 50.3243 18.8417L49.4834 20.7108C49.8071 20.8727 50.1562 21.0163 50.5298 21.1408C50.9162 21.2654 51.2717 21.3281 51.5953 21.3281C52.2435 21.3281 52.7726 21.1972 53.1845 20.9354C53.5954 20.6863 53.9381 20.3308 54.2127 19.8699C54.4872 19.409 54.7545 18.8726 55.0163 18.2626L59.2965 8.01946H56.81L54.1563 14.7188L54.1581 14.7179Z"
        fill="#060606"
      />
      <path
        d="M114.679 8.24312C114.156 7.94402 113.52 7.79492 112.773 7.79492C112.026 7.79492 111.439 7.9822 110.904 8.35585C110.547 8.59677 110.244 8.91314 109.992 9.30316L109.895 8.01948H107.764V17.3653H110.026V12.4678C110.026 11.6451 110.206 11.0159 110.567 10.5796C110.941 10.1314 111.465 9.91318 112.137 9.925C112.511 9.93773 112.836 10.025 113.109 10.1868C113.397 10.3359 113.62 10.6041 113.782 10.9905C113.944 11.3641 114.025 11.8942 114.025 12.5796V17.3653H116.306V12.0751C116.306 11.0778 116.163 10.2677 115.876 9.64499C115.602 9.00951 115.203 8.54222 114.679 8.24312Z"
        fill="#060606"
      />
      <path
        d="M104.262 8.33676C103.652 7.97584 102.879 7.79492 101.944 7.79492C101.208 7.79492 100.579 7.91311 100.056 8.15039C99.5329 8.37494 99.1029 8.67404 98.7656 9.04769C98.4292 9.40862 98.1674 9.78954 97.981 10.1877L99.9811 10.8978C100.205 10.5241 100.486 10.2432 100.822 10.0568C101.159 9.86954 101.532 9.77681 101.944 9.77681C102.405 9.77681 102.773 9.88863 103.047 10.1132C103.32 10.3377 103.52 10.6614 103.645 11.085C103.71 11.2814 103.759 11.5005 103.794 11.7396H101.514C100.317 11.7396 99.3893 11.9951 98.7292 12.506C98.0692 13.0042 97.7383 13.746 97.7383 14.7306C97.7383 15.6407 98.0437 16.3443 98.6547 16.8425C99.2774 17.3407 100.15 17.5898 101.271 17.5898C102.392 17.5898 103.202 17.2344 103.7 16.5243C103.764 16.4325 103.823 16.3361 103.879 16.2352L103.925 17.3653H106.037L106.093 12.206C106.105 11.296 105.956 10.5114 105.645 9.85045C105.346 9.19043 104.885 8.68495 104.261 8.33676H104.262ZM103.551 14.5988C103.365 14.9225 103.103 15.1843 102.767 15.3843C102.43 15.5834 102.044 15.6834 101.608 15.6834C101.122 15.6834 100.741 15.5961 100.467 15.4216C100.194 15.2343 100.057 14.9852 100.057 14.6743C100.057 14.2879 100.219 13.9888 100.543 13.777C100.867 13.5651 101.44 13.4588 102.262 13.4588H103.851V13.5333C103.851 13.907 103.752 14.2624 103.552 14.5988H103.551Z"
        fill="#060606"
      />
      <path
        d="M123.918 17.3654H126.611L122.797 11.9279L126.312 8.01957H123.583L120.237 11.6761V4.28125H117.975V17.3654H120.237V14.7753L121.33 13.5589L123.918 17.3654Z"
        fill="#060606"
      />
      <path
        d="M78.1338 14.718L75.3664 8.01953H72.8809L77.0692 17.2853L76.6192 18.3372C76.4946 18.6363 76.3264 18.8545 76.1146 18.9917C75.9155 19.1408 75.6791 19.2163 75.4046 19.2163C75.2791 19.2163 75.1182 19.1854 74.9182 19.1227C74.7318 19.0599 74.5255 18.9672 74.3018 18.8426L73.4609 20.7118C73.7845 20.8736 74.1336 21.0173 74.5073 21.1418C74.8937 21.2664 75.2491 21.3291 75.5728 21.3291C76.2201 21.3291 76.7501 21.1982 77.1619 20.9364C77.5729 20.6873 77.9156 20.3318 78.1901 19.8709C78.4638 19.4099 78.732 18.8736 78.9938 18.2635L83.2749 8.02044H80.7884L78.1347 14.7189L78.1338 14.718Z"
        fill="#060606"
      />
      <path
        d="M94.8716 10.5615C95.3452 10.2496 95.7125 9.876 95.9743 9.43962C96.2361 8.99142 96.3671 8.49867 96.3671 7.9632C96.3671 7.15317 96.1807 6.47406 95.8061 5.92585C95.4452 5.37765 94.9279 4.96673 94.2552 4.69217C93.5942 4.41762 92.816 4.28125 91.9187 4.28125H87.5449V17.3654H92.5169C93.3888 17.3654 94.1433 17.2217 94.7779 16.9353C95.4261 16.649 95.9243 16.2317 96.2734 15.6826C96.6343 15.1216 96.8153 14.4489 96.8153 13.6643C96.8153 12.9416 96.6471 12.3252 96.3107 11.8142C95.9734 11.2906 95.4943 10.8733 94.8716 10.5615ZM89.8623 6.18768H91.8069C92.4796 6.18768 93.0033 6.34314 93.377 6.65497C93.7633 6.95407 93.9561 7.38409 93.9561 7.94502C93.9561 8.50595 93.7815 8.92324 93.4324 9.23506C93.096 9.53417 92.6351 9.68326 92.0496 9.68326H89.8623V6.18768ZM93.8433 14.7853C93.4697 15.0971 92.9524 15.2526 92.2915 15.2526H89.8614V11.6452H92.0487C92.7833 11.6452 93.3569 11.807 93.7679 12.1315C94.1924 12.4552 94.4033 12.9043 94.4033 13.477C94.4033 14.0498 94.2161 14.4616 93.8424 14.7853H93.8433Z"
        fill="#060606"
      />
      <path
        d="M70.9494 8.39322C70.3139 7.99411 69.5721 7.79501 68.7248 7.79501C67.9402 7.79501 67.2665 7.99411 66.7056 8.39322C66.3647 8.64141 66.072 8.95506 65.8274 9.33325V4.28125H63.5664V17.3654H65.7156L65.8029 16.0117C66.0529 16.4081 66.3538 16.7353 66.7065 16.9917C67.2675 17.3908 67.9402 17.5899 68.7257 17.5899C69.573 17.5899 70.3148 17.3908 70.9503 16.9917C71.5858 16.5926 72.0785 16.0262 72.4267 15.2907C72.7758 14.5434 72.9504 13.677 72.9504 12.6925C72.9504 11.7079 72.7758 10.8297 72.4267 10.0942C72.0785 9.35871 71.5858 8.79232 70.9503 8.39322H70.9494ZM70.2767 14.1498C70.0639 14.5734 69.7721 14.9035 69.3984 15.1407C69.0366 15.3771 68.6193 15.4962 68.1457 15.4962C67.6975 15.4962 67.2984 15.378 66.9493 15.1407C66.6002 14.9044 66.3265 14.5734 66.1265 14.1498C65.9265 13.7261 65.8274 13.2398 65.8274 12.6916C65.8274 12.1434 65.9265 11.657 66.1265 11.2333C66.3265 10.8097 66.6002 10.4797 66.9493 10.2424C67.3102 10.006 67.7156 9.88691 68.1638 9.88691C68.6375 9.88691 69.0548 10.0051 69.4166 10.2424C69.7903 10.4787 70.0766 10.8097 70.2767 11.2333C70.4885 11.657 70.5939 12.1434 70.5939 12.6916C70.5939 13.2398 70.4885 13.7261 70.2767 14.1498Z"
        fill="#060606"
      />
    </svg>
  );
}
