import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { ConsumerBrandCheckout } from "./consumerBrandCheckout";
import { ICON_MAP } from "./iconMap";
import { getFakeButtonStyles } from "./styles.css";
import { type AnimLayerFakeButtonProps } from "./types.d";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export function AnimLayerFakeButton({
  className: userClassName,
  children,
  ctaIcon: rightCtaIcon,
  leftCtaIcon,
  size,
  variant = "secondary",
  payByBank,
  bankLogos,
  borderRadius,
  ...rest
}: AnimLayerFakeButtonProps) {
  const dynamicIconRight = rightCtaIcon && ICON_MAP[rightCtaIcon];
  const dynamicIconLeft = leftCtaIcon && ICON_MAP[leftCtaIcon];

  if (variant === "paybybank") {
    return (
      <ConsumerBrandCheckout
        bankLogos={bankLogos}
        payByBank={payByBank}
        variant={variant}
        size={size}
        borderRadius={borderRadius || "sm"}
        {...rest}
      />
    );
  }

  if (!children) return null;

  if (variant === "branded") {
    // TODO change to a switch statement?
    return (
      <StoryblokEditable {...rest}>
        <ConsumerBrandCheckout
          ctaIcon={rightCtaIcon}
          borderRadius={borderRadius || "sm"}
          variant={variant}
          {...rest}
        >
          {children}
        </ConsumerBrandCheckout>
      </StoryblokEditable>
    );
  }

  return (
    <StoryblokEditable {...rest}>
      <Box
        className={clsx(getFakeButtonStyles({ size, variant }), userClassName)}
        {...rest}
      >
        {dynamicIconLeft ? (
          <IconFontAwesome icon={dynamicIconLeft as IconProp} />
        ) : (
          <div />
        )}
        {children}
        {dynamicIconRight ? (
          <IconFontAwesome icon={dynamicIconRight as IconProp} />
        ) : (
          <div />
        )}
      </Box>
    </StoryblokEditable>
  );
}
