import React, { useContext } from "react";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { PageQueryContext } from "~context/PageQueryContext";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Abstract of single ecommerce item
 * ------------------------------------------------------------------------------
 */

export interface EcommerceItemProps {
  image?: StoryblokFieldMedia;
  title?: string;
  amount?: number | string;
}

export function EcommerceItem({
  image,
  title,
  amount,
  ...rest
}: EcommerceItemProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <Box display="flex" alignItems="stretch" gap="phoneSpacing2">
        {image && (
          <Box
            className={styles.itemImg}
            padding="phoneSpacing1"
            borderRadius="sm"
            aspectRatio="square"
            marginY="auto"
          >
            <Image
              objectFit="contain"
              loading="lazy"
              width="image_md"
              aspectRatio="square"
              image={image}
            />
          </Box>
        )}
        <Box
          textAppearance="phone_text_md"
          width="100%"
          display="flex"
          flexDirection="column"
          gap="phoneSpacing1"
        >
          {title && (
            <Box data-motion={ANIMATED.secondary} fontWeight="semibold">
              {title}
            </Box>
          )}
          <Box
            display="flex"
            flexDirection="row"
            textAppearance="phone_text_sm"
          >
            <Box
              gap="phoneSpacing1"
              paddingX="phoneSpacing1"
              borderRadius="sm"
              data-motion={ANIMATED.secondary}
              className={styles.itemQuantity}
            >
              x1
            </Box>
            {amount && (
              <Box data-motion={ANIMATED.secondary} color="text_lowContrast">
                {formatCurrencyString({
                  amount,
                  lang,
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Component that renders a list of ecommerce items
 * (e.g. items someone may have in their cart in an ecommerce checkout )
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerEcommerceItemsProps extends BoxProps, StoryblokBlok {
  items: Array<StoryblokBlok & EcommerceItemProps>;
}

export function AnimLayerEcommerceItems({
  items,
  ...rest
}: AnimLayerEcommerceItemsProps) {
  if (!Array.isArray(items)) return null;

  return (
    <Box display="flex" flexDirection="column" gap="phoneGutteringSm" {...rest}>
      {items.map((item) => {
        return <EcommerceItem key={item._uid} {...item} />;
      })}
    </Box>
  );
}
