import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import * as styles from "./styles.css";

import type { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerInputProps extends StoryblokBlok, BoxProps {
  title: string;
  icon?: IconDefinition | null;
  appearance?: "default" | "pill";
  status?: "default" | "selected";
}

export function AnimLayerInput({
  title,
  icon,
  appearance = "default",
  status = "default",
  ...rest
}: AnimLayerInputProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box
        className={clsx(styles.getFakeInputStyle({ appearance, status }))}
        {...rest}
      >
        <ShowChildren when={icon}>
          <IconFontAwesome color="text_extraLowContrast" icon={icon!} />
        </ShowChildren>
        {title}
      </Box>
    </StoryblokEditable>
  );
}
