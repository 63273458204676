import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { ImageBackground } from "~components/ImageBackground";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { SvgIllustrationCards } from "~animations/__layers__/SvgIllustrationCards";
import { SvgIllustrationPokerChip } from "~animations/__layers__/SvgIllustrationPokerChip";

import { AnimLayerUiCardTitle } from "../AnimLayerUiCardTitle";
import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

/**
 * ------------------------------------------------------------------------------
 * Map of bespoke iGaming related SVG illustrations as react components
 * ------------------------------------------------------------------------------
 */

const ILLUSTRATION_MAP = {
  SvgIllustrationCards,
  SvgIllustrationPokerChip,
  "": () => {
    return null;
  },
} as const;

type IllustrationKey = keyof typeof ILLUSTRATION_MAP;

/**
 * ------------------------------------------------------------------------------
 * Abstraction of single item in the iGaming illustrations layer
 * ------------------------------------------------------------------------------
 */

interface AnimLayerIgamingCardsItemProps {
  illustration?: IllustrationKey;
  image?: StoryblokFieldMedia;
  title: string;
  description?: string;
}

function AnimLayerIgamingCardsItem({
  illustration = "",
  image,
  title,
  description,
  ...rest
}: AnimLayerIgamingCardsItemProps) {
  const Illustration = ILLUSTRATION_MAP[illustration];

  return (
    <StoryblokEditable {...rest}>
      <Box
        position="relative"
        display="flex"
        minWidth="gridSpan2"
        maxHeight="phoneSpacing16"
        borderRadius="md"
        alignItems="center"
        overflow="hidden"
        className={variantBackgroundColor.background_mockUiCard}
        data-motion={ANIMATED.secondary}
      >
        <Box padding="phoneSpacing2" flexShrink="0" width="50%">
          <Box
            textAppearance="phone_text_lg"
            fontWeight="semibold"
            textTransform="uppercase"
          >
            {title}
          </Box>
          {description && (
            <Box
              fontWeight="light"
              color="text_lowContrast"
              textAppearance="phone_text_sm"
            >
              {description}
            </Box>
          )}
        </Box>
        <ShowChildren when={image}>
          <ImageBackground image={image!} className={styles.bgImage} />
        </ShowChildren>
        <ShowChildren when={illustration}>
          <Box minWidth="phoneSpacing16">
            {illustration && <Illustration />}
          </Box>
        </ShowChildren>
      </Box>
    </StoryblokEditable>
  );
}

/**
 * ------------------------------------------------------------------------------
 * Main component - renders a list of cards with iGaming related illustrations and title
 * ------------------------------------------------------------------------------
 */

export interface AnimLayerIgamingCardsProps extends BoxProps, StoryblokBlok {
  items: Array<StoryblokBlok & AnimLayerIgamingCardsItemProps>;
  title?: string;
}

export function AnimLayerIgamingCards({
  items,
  title,
  ...rest
}: AnimLayerIgamingCardsProps) {
  if (!Array.isArray(items)) return null;

  return (
    <StoryblokEditable {...rest}>
      <Box {...rest}>
        {title && <AnimLayerUiCardTitle title={title} />}
        <Box display="flex" alignItems="stretch" gap="phoneSpacing1">
          {items.map((item) => {
            return <AnimLayerIgamingCardsItem key={item._uid} {...item} />;
          })}
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
