import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { BankLogos } from "../BankLogos/BankLogos";
import { PayByBankSVG } from "./PayByBankSVG";
import { ICON_MAP } from "./iconMap";
import { getFakeButtonStyles } from "./styles.css";
import * as styles from "./styles.css";
import { type AnimLayerFakeButtonProps } from "./types.d";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";

export function ConsumerBrandCheckout({
  bankLogos,
  payByBank,
  children,
  ctaIcon,
  variant,
  size,
  borderRadius = "sm",
  ...rest
}: AnimLayerFakeButtonProps) {
  const dynamicIcon = ctaIcon && ICON_MAP[ctaIcon];

  return (
    <StoryblokEditable {...rest}>
      <Box
        data-testid="consumer-brand-checkout-button"
        position="relative"
        className={clsx(getFakeButtonStyles({ size, variant, borderRadius }))}
        {...rest}
      >
        {children}
        <ShowChildren when={dynamicIcon}>
          <IconFontAwesome
            position="absolute"
            right="spacing2"
            icon={dynamicIcon as IconProp}
          />
        </ShowChildren>
        <ShowChildren when={payByBank}>
          <Box className={styles.payByBank} maxWidth="75%">
            <PayByBankSVG height="50%" width="100%" />
          </Box>
        </ShowChildren>
        <ShowChildren when={bankLogos}>
          <Box maxWidth="25%" className={styles.banksLogos}>
            <Box
              height="50%"
              maxWidth="75%"
              display="flex"
              justifyContent="center"
            >
              <BankLogos />
            </Box>
          </Box>
        </ShowChildren>
      </Box>
    </StoryblokEditable>
  );
}
