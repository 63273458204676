import React from "react";

import { faCalendarCircleExclamation } from "@fortawesome/sharp-regular-svg-icons/faCalendarCircleExclamation";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";

import type { AnimLayerFakeButtonProps } from "../AnimLayerFakeButton/types";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerPaymentDueProps extends BoxProps {
  title?: string;
  description?: string;
  button1?: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  button2?: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
}

export function AnimLayerPaymentDue({
  title,
  description,
  button1: button1BlokArray,
  button2: button2BlokArray,
  ...rest
}: AnimLayerPaymentDueProps) {
  const [button1] = button1BlokArray || [];
  const [button2] = button2BlokArray || [];

  return (
    <Box
      {...rest}
      className={variantBackgroundColor.background_mockUiCard}
      padding="phoneSpacing2"
      borderRadius="md"
      display="flex"
      flexDirection="column"
      gap="phoneSpacing1"
    >
      <Box display="flex" alignItems="center" gap="phoneSpacing1">
        <IconFontAwesome icon={faCalendarCircleExclamation} />
        <Box>
          {title && (
            <Box textAppearance="phone_text_lg" fontWeight="semibold">
              {title}
            </Box>
          )}
        </Box>
      </Box>
      {description && (
        <Box textAppearance="phone_text_sm" marginBottom="phoneSpacing1">
          {description}
        </Box>
      )}
      {button1 && <AnimLayerFakeButton size="sm" {...button1} />}
      {button2 && (
        <AnimLayerFakeButton size="sm" variant="secondary" {...button2} />
      )}
    </Box>
  );
}
