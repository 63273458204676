import { faArrowDownToLine } from "@fortawesome/sharp-regular-svg-icons/faArrowDownToLine";
import { faArrowRightLong } from "@fortawesome/sharp-regular-svg-icons/faArrowRightLong";
import { faPlus } from "@fortawesome/sharp-regular-svg-icons/faPlus";
import { faBolt } from "@fortawesome/sharp-solid-svg-icons/faBolt";
import { faLock } from "@fortawesome/sharp-solid-svg-icons/faLock";

import type { IconDefinition as IconDefinitionRegular } from "@fortawesome/sharp-regular-svg-icons";
import type { IconDefinition as IconDefinitionSolid } from "@fortawesome/sharp-solid-svg-icons";

export const ICON_MAP: Record<
  string,
  IconDefinitionRegular | IconDefinitionSolid
> = {
  faArrowRightLong,
  faArrowDownToLine,
  faPlus,
  faLock,
  faBolt,
} as const;
